import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import Navbar from "../../components/navbar";

import Footer from "../components/footer";
import Switcher from "../../components/switcher";

import {FiCamera} from '../../assets/icons/vander'

import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

import {FiEye} from '../../assets/icons/vander'

import i18n from "../../i18n";
import { fetchThumbnail } from "../../api/information";
import { fetchTravelByID } from "../../api/travel";
import { Helmet } from "react-helmet";


export default function TourDetailOne(){
    let [isLoading, setIsLoading] = useState(true)
        let [item, setitem] = useState([])
        let [imageShows, setImageShows] = useState([])
        let [images, setImages] = useState([])
    
        let [photoIndex, setActiveIndex] = useState(0);
        let [isOpen, setOpen] = useState(false);
        let [form, setForm] = React.useState({});
    
        const changeValue = (e) => {
            setForm({
              ...form,
              [e.target.name]: e.target.value,
            })
        }
    
        let handleCLick = (index) => {
            setActiveIndex(index)
            setOpen(true);
        }
    
        let imageCss = [
            "md:col-span-8 col-span-7",
            "md:col-span-4 col-span-5",
            "md:col-span-4 col-span-5",
            "md:col-span-8 col-span-7",
        ]
    
        let params = useParams()
        let id = params.id
    
        React.useEffect(()=>{
            fetchTravelByID(id).then((resp) => {
                console.log(resp);
                resp.options = resp.options?.map((data) => {
                    data.items = data.items.map((items, index) => {
                        items.selected = (index == 0 ? true : false)
                        return items
                    })
                    return data
                })
    
                setitem(resp);
                if(resp.images.length > 0){
                    let imgs = [];
                    let imgShows = [];
                    resp.images.forEach(element => {
                        if(element?.url !== ""){
                            imgs.push(element?.url);
                            if(imgShows.length <= 3){
                                imgShows.push(element?.url);
                            }
                        }
                    });
                    setImages(imgs);
                    setImageShows(imgShows);
                }
                setIsLoading(true);
            })
        },[id])

    const [thumbnail, setThumbnail] = React.useState({});
    
    React.useEffect(()=>{
        fetchThumbnail("Blog").then((resp)=>{
            setThumbnail(resp.image?.url);
        })
    },[])
    return(
        <>
        <Helmet>
            <title>{item?.name}</title>
            <meta property="og:title" content={item?.name} />
            <meta property="og:description" content={item.description} />
            {
                (item.image?.length > 0) ?? <meta property="og:image" content={item.image[0]?.url}/>
            }
            <meta property="og:type" content="website" />
        </Helmet>
         <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
         <section 
                className="relative table w-full items-center py-36 bg-top bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${thumbnail})` }}
            >
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-3xl leading-normal tracking-wider font-semibold text-white">{i18n.t("blog")}</h3>
                </div>
            </div>
        </section>
        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    <div className="lg:col-span-8 md:col-span-7">
                        <div className="grid grid-cols-12 gap-4">
                            {
                                imageShows?.map((image, index, array) => {
                                    var style = imageCss[index];
                                    if(index === (array.length - 1)){
                                        if(index % 2 === 0){
                                            style = "md:col-span-12 col-span-12";
                                        }
                                    }
                                    return (
                                        <div className={style} key={index}>
                                            <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                                                <img src={image} className="w-full lg:h-60 md:h-44 h-48 object-cover" alt=""/>
                                                <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                                <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                                                    <Link to="#" onClick={() => {
                                                        handleCLick(index);
                                                        return false;
                                                    }} className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"><FiCamera className="size-4 align-middle"></FiCamera></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <h5 className="text-2xl font-semibold mt-5">{item?.name ? item.name : ''}</h5>

                        <div className="flex mb-4">
                            <span className="flex items-center text-slate-400 text-sm"><FiEye className="size-4 text-slate-900 dark:text-white me-1.5"></FiEye>{item.viewer}</span>
                            {/* <span className="text-slate-400 text-sm ms-3">by <Link to="" className="text-slate-900 dark:text-white hover:text-red-500 dark:hover:text-red-500 font-medium">{item.user?.first_name}</Link></span> */}
                        </div>
                    
                        <div className="mt-6">
                        <p className="text-slate-400 mt-6" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>


                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="sticky top-20">
                            <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">{i18n.t("author")}</h5>
                            <div className="text-center mt-8">
                                <img src={item.user?.image?.url} className="h-20 w-20 mx-auto rounded-full shadow mb-4" alt=""/>

                                <Link to="" className="text-lg font-medium hover:text-red-500 transition-all duration-500 ease-in-out h5">{item.user?.first_name} {item.user?.last_name}</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <Footer/>
        <Switcher/>
        {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen( false )}
                    onMovePrevRequest={() =>
                        setActiveIndex((photoIndex + images.length - 1) % images.length,
                        )
                    }
                    onMoveNextRequest={() =>
                        setActiveIndex((photoIndex + 1) % images.length,
                        )
                    }
                />
            )}
        </>
    )
}