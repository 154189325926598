import React from "react";
import { Link } from "react-router-dom";

import Filter from "./filter";

import {FiMapPin} from '../../assets/icons/vander'
import { fetchTravel } from "../../api/travel";
import { useLocation } from 'react-router-dom';
import i18n from "../../i18n";
import Pagination from "../components/pagination";

export default function List({travelType, actionPath, actionDetail}){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 1;
    const countryID = queryParams.get('country_id') || 0;
    const provinceID = queryParams.get('province_id') || 0;
    const min = queryParams.get('min') || 0;
    const max = queryParams.get('max') || 0;
    const rating = queryParams.get('rating') || "";
    const category = queryParams.get('category') || "";
    
    const [travels, setTravels] = React.useState([]);
    const [pagination, setPagination] = React.useState([]);

    React.useEffect(()=>{
        getData(page);
        console.log("countryID", countryID)
        console.log("provinceID", provinceID)
    },[page, countryID, provinceID])

    const getData = (page) => {
        let params = [];
        if (countryID > 0) {
            params.push("country_id=" + countryID);
        }
        if (provinceID > 0) {
            params.push("province_id=" + provinceID);
        }
        if (min > 0) {
            params.push("min=" + min);
        }
        if (max > 0) {
            params.push("max=" + max);
        }
        if (rating !== "") {
            params.push("rating=" + rating);
        }
        if (category !== "") {
            params.push("category=" + category);
        }
        var filter = "";
        if (params.length > 0) {
            filter += "&order_by=created_at&order_type=desc&published=true&" + params.join("&");
        }

        fetchTravel(
            {
                page: page, 
                limit: 10, 
                travelType: travelType, 
                filter: filter
            }).then((resp)=>{
                setTravels(resp.data);
                let pag = resp.pagination;
                setPagination({
                    url: actionPath,
                    params: params,
                    page: pag.page,
                    pageSize: pag.page_size,
                    total: pag.total,
                    totalPage: pag.total_page,
                });
        })
    }

    return (
        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    
                    <div className="lg:col-span-8 md:col-span-7">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
                            {travels.map((item,index)=>{
                                return(
                                    <div className="group rounded-md shadow dark:shadow-gray-700" key={index}>
                                        <div className="relative overflow-hidden rounded-t-md shadow dark:shadow-gray-700 mx-3 mt-3">
                                            <a href={`${actionDetail}/${item.id}`}>
                                                <img src={item.images[0]?.url} className="scale-125 group-hover:scale-100 duration-500" alt=""/>
                                            </a>
                                            {item.tagText && (
                                                <div className="absolute top-0 start-0 p-4">
                                                    <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tagText}</span>
                                                </div>
                                            )}
            
                                            {/* <div className="absolute top-0 end-0 p-4">
                                                <Link to="#" className="size-8 inline-flex justify-center items-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-500 dark:focus:text-red-500 hover:text-red-500 dark:hover:text-red-500"><i className="mdi mdi-heart text-[20px] align-middle"></i></Link>
                                            </div> */}
                                        </div>
            
                                        <div className="p-4">
                                            <p className="flex items-center text-slate-400 font-medium mb-2"><FiMapPin className="text-red-500 size-4 me-1">
                                                </FiMapPin> {item.province?.name} / {item.province.name}
                                            </p>
                                            <Link to={`${actionDetail}/${item.id}`} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.name}</Link>
            
                                            <div className="flex items-center mt-2">
                                                <span className="text-slate-400">Rating:</span>
                                                <ul className="text-lg font-medium text-amber-400 list-none ms-2 space-x-1">
                                                    {[...Array(5)].map((_, index) => (
                                                        <li className="inline" key={index}>
                                                            <i className={`mdi ${item.rating >= index + 1 ? 'mdi-star' : 'mdi-star-outline'} align-middle`}></i>
                                                        </li>
                                                    ))}
                                                    <li className="inline text-black dark:text-white text-sm">{item.rating}</li>
                                                </ul>
                                            </div>
                                            
                                            <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                                                <h5 className="text-lg font-medium text-red-500">
                                                {travelType !== "Blog" && (
                                                    item.discount > 0 ? (
                                                        <>
                                                        <s className="text-gray-500">{item.price?.toLocaleString()}</s> 
                                                        {item.discount?.toLocaleString()}
                                                        </>
                                                    ) : (item.price?.toLocaleString())
                                                )}
                                                </h5>
            
                                                <Link to={`${actionDetail}/${item.id}`} className="text-slate-400 hover:text-red-500">{i18n.t("booking_now")}<i className="mdi mdi-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <Pagination {...pagination} />
                    </div>

                    <Filter travelType={travelType} actionPath={actionPath} />

                </div>
            </div>
        </section>
    )
}