import React, { useState } from 'react';

import {FiSearch} from '../../assets/icons/vander'
import i18n from '../../i18n';
import { useNavigate } from 'react-router-dom';
import { fetchIndexButton } from '../../api/information';

export default function Search(){
    const [text, setText] = React.useState("");
    const navigate = useNavigate();
    const navigateTo = () => navigate('/search?search='+text);
    const [indexButton, setIndexButton] = React.useState({});

    React.useEffect(()=>{
        fetchIndexButton().then((resp)=>{
            setIndexButton(resp);
        });
    },[])

    return(
        (indexButton.link == "")
        ? <div className="grid grid-cols-1">
            <form onSubmit={(e)=>{
                e.preventDefault();
                navigateTo();
            }} className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                <div className="registration-form text-dark text-start">
                    <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-4">
                        <div>
                            <label className="form-label font-medium text-slate-900 dark:text-white">{i18n.t("search")}:</label>
                            <div className="relative mt-2">
                                <FiSearch className="size-[18px] absolute top-[10px] start-3"></FiSearch>
                                <input name="name" type="text" id="job-keyword" 
                                onChange={(e) => setText(e.target.value)}
                                className="w-full py-2 px-3 ps-10 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-md outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder={i18n.t("search")}/>   
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        : <div className="grid grid-cols-1">
            <form onSubmit={(e)=>{
                e.preventDefault();
                navigateTo();
            }} className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                <div className="registration-form text-dark text-start">
                    <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-4">
                        <div>
                            <div className="relative mt-2 text-center">
                            <input onClick={() => window.open(indexButton.link, "_blank")}  type="submit" value={indexButton.button_name} id="search-buy" name="search" className="py-1 px-5 h-10 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md cursor-pointer" defaultValue="Search"/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}