import React from "react";
import { Link } from "react-router-dom";

import logoLight from '../../assets/hpt-white.png'

import {FiMapPin, FiMail, FiPhone} from '../../assets/icons/vander'
import { useSelector } from "react-redux";
import i18n from "../../i18n";

export default function Footer(){

    const {
        contact, 
        about
    } = useSelector((state) => state.information);

    return(
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
            <div className="container relative">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="py-[60px] px-0">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                                <div className="lg:col-span-6 md:col-span-12">
                                    <Link to="#" className="text-[22px] focus:outline-none">
                                        <img src={logoLight} width={50} alt=""/>
                                    </Link>
                                    <p className="mt-6 text-white" dangerouslySetInnerHTML={{ __html: about?.footer }} ></p>
                                    <ul className="list-none mt-6 space-x-1">
                                        {
                                            contact?.social?.map((data,index) => 
                                                <li 
                                                className="inline w-8 h-8" 
                                                key={index} 
                                                style={{ overflow: "hidden", borderRadius: "6px" }} // Ensures image stays within boundaries
                                                >
                                                    <Link 
                                                        to={data.link} 
                                                        target="_blank" 
                                                        className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:bg-red-500 hover:text-white text-slate-300"
                                                        style={{ backgroundColor: "gray", padding: 1 }}
                                                    >
                                                        <img 
                                                        src={data?.detail?.image?.url} 
                                                        className="w-full h-full object-cover rounded-md"
                                                        alt="icon"
                                                        />
                                                    </Link>
                                                </li>

                                            )
                                        }
                                    </ul>
                                </div>

                                <div className="lg:col-span-6 md:col-span-12">
                                    <div className="lg:ms-8">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">{i18n.t("office")}</h5>
                                        <h5 className="tracking-[1px] text-gray-100 mt-6">{about?.name}</h5>

                                        <div className="flex mt-4">
                                            <FiMapPin className="size-4 text-red-500 me-2 mt-1"></FiMapPin>
                                            <div className="">
                                                <h6 className="text-gray-300">{contact?.address}</h6>
                                            </div>
                                        </div>

                                        <div className="flex mt-4">
                                            <FiMail className="size-4 text-red-500 me-2 mt-1"></FiMail>
                                            <div className="">
                                                <Link to={"mailto:"+contact?.email} className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">{contact?.email}</Link>
                                            </div>
                                        </div>
                        
                                        <div className="flex mt-4">
                                            <FiPhone className="size-4 text-red-500 me-2 mt-1"></FiPhone>
                                            <div className="">
                                                <Link to={"tel:"+contact?.phone} className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">{contact?.phone}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid grid-cols-1">
                        <div className="text-center">
                            <p className="mb-0">© {new Date().getFullYear()} {about?.name} </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}