import React from "react";
import aboutImage from '../../assets/images/about.jpg'
import map from '../../assets/images/map-plane-big.png'

import {FiUsers,FiGlobe} from '../../assets/icons/vander'

import CountUp from 'react-countup';
import i18n from "../../i18n";
import { useSelector } from "react-redux";
import { fetchThumbnail } from "../../api/information";

export default function About(){
    const {about} = useSelector((state) => state.information);

    const [tourCounter, setTourCounter] = React.useState(0);
    const [visitorCourter, setVisitorCourter] = React.useState(0);

    const [thumbnail, setThumbnail] = React.useState({});
    
    React.useEffect(()=>{
        fetchThumbnail("AboutDetail").then((resp)=>{
            setThumbnail(resp.image?.url);
        })
    },[])

    React.useEffect(()=>{
        if(about.tour_counter !== undefined){
            setTourCounter(about.tour_counter)
        }

        if(about.visitor_courter !== undefined){
            setVisitorCourter(about.visitor_courter)
        }
    },[about])

    return(
        <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6 relative">
                <div className="md:col-span-5">
                    <div className="relative"> 
                    <img 
                        src={thumbnail} 
                        style={{ objectFit: "cover" }} 
                        className="mx-auto rounded-3xl shadow dark:shadow-gray-700 w-[90%] h-64" 
                        alt=""
                    />
                        <div className="absolute flex items-center bottom-16 md:-start-10 -start-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-56 m-3">
                            <div className="flex items-center justify-center h-[65px] min-w-[65px] bg-red-500/5 text-red-500 text-center rounded-xl me-3">
                                <FiUsers className="size-6"></FiUsers>
                            </div>
                            <div className="flex-1">
                                <span className="text-slate-400">{i18n.t("visitor")}</span>
                                <p className="text-xl font-bold">
                                    <CountUp className="counter-value" start={0} end={visitorCourter} />
                                </p>
                            </div>
                        </div>

                        <div className="absolute flex items-center top-16 md:-end-10 -end-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-60 m-3">
                            <div className="flex items-center justify-center h-[65px] min-w-[65px] bg-red-500/5 text-red-500 text-center rounded-xl me-3">
                                <FiGlobe className="size-6"></FiGlobe>
                            </div>
                            <div className="flex-1">
                                <span className="text-slate-400">{i18n.t("tours_packages")}</span>
                                <p className="text-xl font-bold"><CountUp className="counter-value" start={0} end={tourCounter}/></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:col-span-7">
                    <div className="lg:ms-8">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{about?.name}</h3>

                        <p
                            className="text-slate-400 max-w-xl mb-6"
                            dangerouslySetInnerHTML={{ __html: about?.history }}
                        ></p>

                        <p
                            className="text-slate-400 max-w-xl mb-6"
                            dangerouslySetInnerHTML={{ __html: about?.vision }}
                        ></p>
                    </div>
                </div>

                <div className="absolute bottom-0 start-1/3 -z-1">
                    <img src={map} className="lg:w-[600px] w-96" alt=""/>
                </div>
            </div>
        </div>
    )
}