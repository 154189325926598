import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../components/footer";
import Switcher from "../../components/switcher";

import {FiChevronRight, FiEye} from '../../assets/icons/vander'

import Pagination from "../components/pagination";
import { useLocation } from 'react-router-dom';
import i18n from "../../i18n";
import { fetchTravel } from "../../api/travel";
import { fetchThumbnail } from "../../api/information";

export default function Blogs(){

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 1;

    const [blog, setBlog] = React.useState([]);
    const [pagination, setPagination] = React.useState({});

    const [thumbnail, setThumbnail] = React.useState({});
    
    React.useEffect(()=>{
        fetchThumbnail("Blog").then((resp)=>{
            setThumbnail(resp.image?.url);
        })
    },[])

    React.useEffect(()=>{
        getData(page);
    },[page])

    const getData = (page) => {
        fetchTravel({page:page, limit:16, travelType:"Blog", filter:"&order_by=created_at&order_type=desc&published=true"}).then((response)=> {
            setBlog(response.data);
            console.log(response.data);
            let pag = response.pagination;
            setPagination({
                url:"/blogs",
                page: pag.page,
                pageSize: pag.page_size,
                total: pag.total,
                totalPage: pag.total_page,
            });
        })
    }

    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section 
                className="relative table w-full items-center py-36 bg-top bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${thumbnail})` }}
            >
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">{i18n.t("blog")}</h3>
                </div>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                    {blog.map((item,index)=>{
                        return(
                            <div className="group relative overflow-hidden" key={index}>
                                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                    <a href={/blog-detail/+item.id}>
                                    <img src={item.images[0]?.url} className="group-hover:scale-110 group-hover:rotate-3 duration-500" alt=""/>
                                    </a>
                                    <div className="absolute top-0 start-0 p-4 opacity-0 group-hover:opacity-100 duration-500">
                                        <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tag}</span>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <div className="flex mb-4">
                                        <span className="flex items-center text-slate-400 text-sm"><FiEye className="size-4 text-slate-900 dark:text-white me-1.5"></FiEye>{item.viewer}</span>
                                        <span className="text-slate-400 text-sm ms-3">by <Link to="" className="text-slate-900 dark:text-white hover:text-red-500 dark:hover:text-red-500 font-medium">{item.user?.first_name}</Link></span>
                                    </div>

                                    <Link to={/blog-detail/+item.id} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.title}</Link>
                                    <p className="text-slate-400 mt-2">{item.sub_title}</p>

                                    <div className="mt-3">
                                        <Link to={/blog-detail/+item.id} className="hover:text-red-500 inline-flex items-center">{i18n.t("read_more")}<FiChevronRight className="size-4 ms-1"></FiChevronRight></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>

                <Pagination 
                    {...pagination}
                />
            </div>
        </section>

        <Footer/>
        <Switcher/>

        </>
    )
}