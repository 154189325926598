import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-MLQC1S4RJ3";

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};
