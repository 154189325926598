import React, { useState } from "react";
import { Link } from "react-router-dom";

import travel from '../../assets/images/travel-train-station.svg'

import Navbar from "../../components/navbar";
import Footer from "../components/footer";
import Switcher from "../../components/switcher";
import Swal from "sweetalert2";

import {FiPhone, FiMail,FiMapPin} from '../../assets/icons/vander'

import i18n from "../../i18n";
import { useSelector } from "react-redux";
import { sentMessage } from "../../api/information";
export default function Contact(){
    const {contact} = useSelector((state) => state.information)
    const [form, setForm] = React.useState({});    

    const changeValue = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })
    }

    const action = () => {

        TrackEvent("Contact", "Click Button", "Sent Contact")

        try {
            sentMessage(form).then((resp)=> {

                Swal.fire({
                    text: i18n.t("success"),
                    icon: 'success',
                    confirmButtonText: i18n.t("ok"),
                    confirmButtonColor: "green"
                }).then(() => {
                    window.location.reload();
                })
            })
           
        } catch (_) {
            Swal.fire({
                text: i18n.t("somethingWentWrong"),
                icon: 'error',
                confirmButtonText: i18n.t("ok"),
                confirmButtonColor: "green"
            })
        }
    }

    const convertEmbedToLink = (embedUrl) => {
        try {
          const urlParams = new URL(embedUrl).searchParams;
          const pb = urlParams.get("pb");
      
          if (!pb) return embedUrl; // If no pb parameter, return original
      
          // Extracting lat & lng from pb parameter (this may vary)
          const parts = pb.split("!");
          const latIndex = parts.findIndex((p) => p.startsWith("2d")) + 1;
          const lngIndex = parts.findIndex((p) => p.startsWith("3d")) + 1;
      
          if (latIndex && lngIndex) {
            const lat = parts[lngIndex].slice(2); // Remove "3d"
            const lng = parts[latIndex].slice(2); // Remove "2d"
            return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
          }
        } catch (e) {
          console.error("Invalid embed URL", e);
        }
        return embedUrl; 
    };

    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={false} manuclass="justify-end"/>
        <div className="container-fluid relative mt-20">
            <div className="grid grid-cols-1">
                <div className="w-full leading-[0] border-0">
                    <iframe src={contact.google_map_src} style={{border:'0'}} title="travosy" className="w-full h-[500px]"></iframe>
                </div>
            </div>
        </div>
        <section className="relative lg:py-24 py-16">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={travel} className="w-full max-w-[500px] mx-auto" alt=""/>
                    </div>

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                <h3 className="mb-6 text-2xl leading-normal font-semibold">{i18n.t("contactus")}</h3>

                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    action();
                                }}>
                                    <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div className="lg:col-span-6">
                                            <label htmlFor="name" className="font-semibold">{i18n.t("name")}:</label>
                                            <input name="name" onChange={changeValue} id="name" type="text" className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="" required/>
                                        </div>
        
                                        <div className="lg:col-span-6">
                                            <label htmlFor="email" className="font-semibold">{i18n.t("email")}:</label>
                                            <input name="email" onChange={changeValue} id="email" type="email" className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="" required/>
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="question" className="font-semibold">{i18n.t("question")}:</label>
                                            <input name="question" onChange={changeValue} id="subject" className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="" required/>
                                        </div>
    
                                        <div className="lg:col-span-12">
                                            <label htmlFor="comment" className="font-semibold">{i18n.t("comment")}:</label>
                                            <textarea name="comment" onChange={changeValue} id="comments" className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder=""></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md mt-2">{i18n.t("send_message")}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
                    <div className="text-center px-6">
                        <div className="relative text-transparent">
                            <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <FiPhone></FiPhone>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="h5 text-lg font-semibold">{i18n.t("phone")}</h5>
                            <div className="mt-5">
                                <Link to={"tel:"+contact.phone} className="text-red-500 font-medium">{contact.phone}</Link>
                            </div>
                        </div>
                    </div>

                    <div className="text-center px-6">
                        <div className="relative text-transparent">
                            <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <FiMail></FiMail>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="h5 text-lg font-semibold">{i18n.t("email")}</h5>
                            <div className="mt-5">
                                <Link to={"mailto:"+contact.email} className="text-red-500 font-medium">{contact.email}</Link>
                            </div>
                        </div>
                    </div>

                    <div className="text-center px-6">
                        <div className="relative text-transparent">
                            <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <FiMapPin></FiMapPin>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="h5 text-lg font-semibold">{i18n.t("address")}</h5>
                            <a target="_blank" href={contact.google_map_link}><p className="text-slate-400 mt-3">{contact.address}</p></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container lg:mt-24 mt-16 flex flex-col items-center">
                <h3 className="text-lg font-bold text-gray-800 dark:text-white mb-4">Socials</h3> 
                <ul className="list-none mt-6 flex justify-center items-center space-x-1">
                    {
                        contact?.social?.map((data, index) => 
                            <li 
                                className="inline w-8 h-8 font-semibold" 
                                key={index} 
                                style={{ overflow: "hidden", borderRadius: "6px" }} 
                            >
                                <Link 
                                    to={data.link} 
                                    target="_blank" 
                                    className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:bg-red-500 hover:text-white text-slate-300"
                                    style={{ backgroundColor: "#fff", padding: 1 }}
                                >
                                    <img 
                                        src={data?.detail?.image?.url} 
                                        className="w-full h-full object-cover rounded-md"
                                        alt="icon"
                                    />
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>

            
        </section>
        <Footer/>
        <Switcher/>
        {/* {modal && (
            <div className="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
                <div className="w-full h-full px-5 md:px-40 md-py-20 py-5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55431.05581015953!2d-95.461302!3d29.735948000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c36647a52ab1%3A0x70a301678672cb!2sBriargrove%20Park%2C%20Houston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1710322657489!5m2!1sen!2sin" width="100%" height="100%" title="myfram" loading="lazy"></iframe>
                </div>
                <button className="text-slate-400 absolute top-[20px] right-[20px]" onClick={()=>setModal(!modal)}>
                    <FiX className="size-5"></FiX>
                </button>
            </div>
        )} */}
        </>
    )
}