import React from "react";
import { Route, Routes } from "react-router-dom";
import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'

import Index from './screens/home/home'
import Contact from './screens/contact/contact'
import Aboutus from './screens/about/aboutus'
import TourList from './screens/travel-list/tour'
import TourDetail from './screens/travel-detail/tour'
import CarRentalList from './screens/travel-list/car_rental'
import CarRentaDetail from './screens/travel-detail/car_rental'
import TicketList from './screens/travel-list/ticket'
import TicketDetail from './screens/travel-detail/ticket'
import Blogs from './screens/blog/blogs'

import IndexTwo from './pages/index/index-two'
import IndexThree from './pages/index/index-three'
import IndexFour from './pages/index/index-four'
import IndexFive from './pages/index/index-five'
import Grid from './pages/listing/tour-grid/grid' 
import GridLeftSidebar from './pages/listing/tour-grid/grid-left-sidebar'
import GridRightSidebar from './pages/listing/tour-grid/grid-right-sidebar'
import List from './pages/listing/tour-list/list'
import ListLeftSidebar from './pages/listing/tour-list/list-left-sidebar'
import ListRightSidebar from './pages/listing/tour-list/list-right-sidebar'
import TourDetailOne from './pages/listing/tour-detail/tour-detail-one'
import TourDetailTwo from './pages/listing/tour-detail/tour-detail-two'

import UserProfile from './pages/account/user-profile'
import UserPayment from './pages/account/user-payment'
import UserInvoice from './pages/account/user-invoice'
import UserSocial from './pages/account/user-social'
import UserNotification from './pages/account/user-notification'
import UserSetting from './pages/account/user-setting'
import Helpcenter from './pages/helpcenter/helpcenter'
import HelpcenterFaq from './pages/helpcenter/helpcenter-faqs'
import HelpcenterGuides from './pages/helpcenter/helpcenter-guides'
import HelpcenterSupport from './pages/helpcenter/helpcenter-support'
import Login from './pages/auth/login'
import Signup from './pages/auth/signup'
import SignupSuccess from './pages/auth/signup-success'
import ForgotPassword from './pages/auth/forgot-password'
import LockScreen from './pages/auth/lock-screen'
import Terms from './pages/utility/terms'
import Privacy from './pages/utility/privacy'
import Comingsoon from './pages/special/comingsoon'
import Maintenance from './pages/special/maintenance'
import Error from './pages/special/404'

import BlogStandard from './pages/blog/blog-standard'
import BlogDetail from './screens/blog/blog-detail'
import SearchDetail from './screens/search/search'
import { fetchAbout, fetchContact } from "./api/information";

import { useDispatch } from "react-redux";
import { SetAbout, SetContact } from "./features/information";
import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";
import { initGA, trackPageView } from "./analytics";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    initGA();
  }, []);

  React.useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  React.useEffect(() => {
    const getContact = () => {
      try {
        fetchContact().then((resp) => {
          dispatch(SetContact(resp));
        })
      } catch (error) {}
    };
    getContact();
  }, [dispatch]);

  React.useEffect(() => {
    const getContact = () => {
      try{
        fetchAbout().then((resp) => {
          dispatch(SetAbout(resp));
        })
      }catch (error){}
    };
    getContact();
  }, [dispatch]);

  return (
    <>
    <Helmet>
      <link rel="icon" type="image/png" href="/favicon.ico?v=M44lzPylqQ" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon.ico?v=M44lzPylqQ" />
      <link rel="mask-icon" sizes="180x180" href="/favicon.ico?v=M44lzPylqQ" />
    </Helmet>
    <Routes>
      <Route path="/" element={<Index/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/aboutus" element={<Aboutus/>}/>
      <Route path="/tour" element={<TourList/>}/>
      <Route path="/tour_detail/:id" element={<TourDetail/>}/>
      <Route path="/car_rental" element={<CarRentalList/>}/>
      <Route path="/car_rental_detail/:id" element={<CarRentaDetail/>}/>
      <Route path="/ticket" element={<TicketList/>}/>
      <Route path="/ticket_detail/:id" element={<TicketDetail/>}/>
      <Route path="/blogs" element={<Blogs/>}/>
      <Route path="/blog-detail/:id" element={<BlogDetail/>}/>
      <Route path="/search" element={<SearchDetail/>}/>
   </Routes>
    </>
  );
}

export default App;
